import { Component, OnInit, OnDestroy } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { filter } from "rxjs/operators"
import { AuthLoginService } from "@service/auth/auth-login.service"
import { Logger } from "@service/global.funcs"

@Component({
  selector: "app-callback",
  templateUrl: "./callback.component.html",
  styleUrls: [],
})
export class CallbackComponent implements OnInit, OnDestroy {
  private _as = null
  private timeoutId: any
  private readonly TIMEOUT_DURATION = 20000 // 10 seconds timeout
  private isAuthenticationComplete = false

  constructor(
    private authLoginService: AuthLoginService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.route.queryParams
      .pipe(filter(params => params._as))
      .subscribe(params => {
        this._as = params._as
      })
  }

  ngOnInit() {
    // Start timeout timer
    this.timeoutId = setTimeout(() => {
      if (!this.isAuthenticationComplete) {
        Logger().error("Auth callback timeout - redirecting to login")
        this.handleAuthenticationFailure()
      }
    }, this.TIMEOUT_DURATION)

    try {
      // Wrap authentication in try-catch
      this.authLoginService
        .handleAuthentication(this._as)
        .then(() => {
          this.isAuthenticationComplete = true
          this.clearTimeout()
        })
        .catch(error => {
          Logger().error("Authentication error:", error)
          this.handleAuthenticationFailure()
        })
    } catch (error) {
      Logger().error("Unexpected authentication error:", error)
      this.handleAuthenticationFailure()
    }
  }

  ngOnDestroy() {
    this.clearTimeout()
  }

  private clearTimeout() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId)
      this.timeoutId = null
    }
  }

  private handleAuthenticationFailure() {
    // Clear any stored auth data
    localStorage.removeItem("access_token")
    localStorage.removeItem("id_token")
    localStorage.removeItem("expires_at")
    localStorage.removeItem("loggedIn")

    // Redirect to login page
    this.router.navigate(["/login"], {
      queryParams: {
        error: "auth_timeout",
        message: "Authentication process timed out. Please try again.",
      },
    })
  }
}
