import { Component, Input, OnChanges, OnInit, SimpleChange } from '@angular/core';
import { Privilege } from '@decorator/index';
import { globalValues } from '@app/globals';
import { CampaignReportService, HelpersService, ToasterLibraryService } from '@service/index';
import { NgxPermissionsService } from 'ngx-permissions';
import { Logger } from '@service/global.funcs';

@Component({
  selector: 'app-sales-by-channel-widget',
  templateUrl: './sales-by-channel-widget.component.html',
  styleUrls: ['./sales-by-channel-widget.component.scss']
})

// @Privilege({
//   privilege: 'campaigns:can_get_campaigns_reports_sales_per_channel'
// })
export class SalesByChannelWidgetComponent implements OnInit, OnChanges {
  @Input() relativeDate: any;
  @Input() campaignData: any;
  @Input() widgetTitle = 'Sales by Channel';
  @Input('chartType') chartType = 'pieChart';
  @Input('useTestData') useTestData = false;

  public salesByChannelData = {
    labels: [],
    datasets: [
      {
        data: []
      }
    ],
    totalCount: 0
  };
  testData = {
    labels: ['2015-01-01', '2015-01-02', '2015-01-03', '2015-01-04', '2015-01-05', '2015-01-06', '2015-01-07'],
    datasets: [
      {
        data: [0, 2, 5, 0, 6, 3, 4]
      }
    ],
    totalCount: 7,
  };
  public salesByChannelLoaded = false;
  constructor(
    private campaignReportService: CampaignReportService,
    private permissionService: NgxPermissionsService,
    private toasterLibrary: ToasterLibraryService,
    private helperService: HelpersService
  ) { }

  ngOnInit() {
    if (this.permissionService.hasPermission('campaigns:can_get_campaigns_reports_sales_per_channel')) {
      if(this.useTestData){
        this.salesByChannelData = this.testData;
        this.salesByChannelLoaded = true;
      }else{
        this.getSalesByChannel();
      }
    }
  }
  ngOnChanges(changes: { [key: string]: SimpleChange }) {
    if (this.permissionService.hasPermission('campaigns:can_get_campaigns_reports_sales_per_channel')) {
      if (
        (changes['relativeDate'] && !changes['relativeDate'].isFirstChange()) ||
        (changes['campaignData'] && !changes['campaignData'].isFirstChange()) ||
        (changes['criteriaArray'] && !changes['criteriaArray'].isFirstChange())
      ) {
        if(this.useTestData){
          this.salesByChannelData = this.testData;
          this.salesByChannelLoaded = true;
        }else{
          this.getSalesByChannel();
        }
      }
    }
  }

  getSalesByChannel() {
    const criteriaArray: any = [
      {
        id: '',
        is_required: true,
        criterion: 'dateRange',
        value: {
          start_date: '',
          end_date: '',
          relative_date: this.relativeDate
        }
      },
      {
        id: '',
        is_required: true,
        criterion: 'statusSubstatus',
        value: {
          ostat_id: 2,
          ss_id: 0
        }
      }
    ];

    if (this.campaignData && this.campaignData != "") {
      criteriaArray.push({
        'id': '',
        'value': { c_id: this.campaignData },
        'criterion': 'campaign',
        'is_required': true
      });
    }

    this.salesByChannelLoaded = false;
    this.reInit();
    this.campaignReportService.getLeadsByChannel(criteriaArray).subscribe(
      (res: any) => {
        if (res.success === 'true') {
          let fifthOptionCount = 0;
          let aggregateIndex = 0;
          res.data.forEach((channel, index) => {
            let cat = channel.category;
            // if (typeof cat === 'string' && cat.length >= globalValues.pie_chart_label_length) {
            //   cat = cat.substr(0, globalValues.pie_chart_label_length) + '...';
            // }

            if (index < globalValues.pie_chart_slices) {
              if (!this.salesByChannelData.labels.includes(cat)) {
                this.salesByChannelData.labels.push(cat);
                this.salesByChannelData.datasets[0].data.push(channel.number_of_occurrences);
              } else {
                aggregateIndex = this.salesByChannelData.labels.indexOf(cat);

                this.salesByChannelData.datasets[0].data[aggregateIndex] =
                  parseInt(this.salesByChannelData.datasets[0].data[aggregateIndex], 10) +
                  parseInt(channel.number_of_occurrences, 10);

                Logger().info(
                  'getSalesByChannel',
                  'pie chart data',
                  'new channel',
                  this.salesByChannelData.datasets[0].data[aggregateIndex]
                );
              }
            } else {
              fifthOptionCount = fifthOptionCount + parseInt(channel.number_of_occurrences, 10);
            }
            this.salesByChannelData.totalCount =
              this.salesByChannelData.totalCount + parseInt(channel.number_of_occurrences, 10);
          });
          if (fifthOptionCount > 0) {
            this.salesByChannelData.labels.push('All Other');
            this.salesByChannelData.datasets[0].data.push(fifthOptionCount);
          }
        }
        this.salesByChannelLoaded = true;
      },
      err => {
        this.toasterLibrary.error(null, err.error.message);
      }
    );

    // this.salesByChannelLoaded = false;
    // this.reInit();
    // this.campaignReportService.channelsGeneratedReportWithSale(this.relativeDate).subscribe(
    //   (res: any) => {
    //     if (res.success === 'true') {
    //       let fifthOptionCount = 0;
    //       res.data.forEach((record, index) => {
    //         if (record.channel) {
    //           let cat = record.channel;
    //           if (typeof cat === 'string' && cat.length >= 10) {
    //             cat = cat.substr(0, 10) + '...';
    //           }
    //           if (index < globalValues.pie_chart_slices) {
    //             this.salesByChannelData.labels.push(cat);
    //             this.salesByChannelData.datasets[0].data.push(record.total_sales);
    //           } else {
    //             fifthOptionCount = fifthOptionCount + parseInt(record.total_sales, 10);
    //           }
    //           this.salesByChannelData.totalCount =
    //             this.salesByChannelData.totalCount + parseInt(record.total_sales, 10);
    //         }
    //       });
    //       if (fifthOptionCount > 0) {
    //         this.salesByChannelData.labels.push('All Other');
    //         this.salesByChannelData.datasets[0].data.push(fifthOptionCount);
    //       }
    //     }
    //     this.salesByChannelLoaded = true;
    //   },
    //   err => {
    //     this.toasterLibrary.error(null, err.error.message);
    //   }
    // );
  }

  exportChartData() {
    const orgId = localStorage.getItem("account_id") ?? "0";

    this.salesByChannelLoaded = false
    this.helperService.chartCSVDownload(this.salesByChannelData, `[${orgId}] ${this.widgetTitle}`)

    setTimeout(() => {
      this.salesByChannelLoaded = true
    }, 500);
  }

  private reInit() {
    this.salesByChannelData = {
      labels: [],
      datasets: [
        {
          data: []
        }
      ],
      totalCount: 0
    };
  }
}
