<ng-template [ngxPermissionsOnly]="['contacts:can_get_contacts_reports_captured']">
  <div class="box-row panel panel-default">
    <div class="panel-heading">
      <div class="d-flex align-items-center justify-content-space-between">
        <h4>{{chartLabel}}</h4>
        <div *ngIf="isContactCapturedLoaded" class="refresh-listing-icon" title="Export"
          (click)="exportChartData()">
          <i class="fa fa-cloud-download fa-lg" aria-hidden="true"></i>
        </div>
      </div>
    </div>
    <div class="panel-body text-center text-alpha">
      <div *ngIf="!isContactCapturedLoaded" class="whirl standard spinnermargin"></div>
      <div *ngIf="isContactCapturedLoaded">
        <div class="col-lg-12 col-sm-12 p0">
          <line-chart [chartData]="leadsChartData" [totalCount]="capturedCount"></line-chart>
        </div>
      </div>
    </div>
  </div>
</ng-template>