import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  OnChanges,
} from "@angular/core"
import { FormControl, FormGroup, Validators } from "@angular/forms"
import { FormFieldBase } from "./form-field/form-field-base"
import { ContactService, FormService } from "@service/index"
import { DropdownField } from "./form-field/dropdown-field"
import { CheckboxField } from "./form-field/checkbox-field"
import { TextAreaField } from "./form-field/textarea-field"
import { TextboxField } from "./form-field/textbox-field"
import { Observable } from "rxjs/Rx"
import * as _ from "lodash"
import { Logger } from "@service/global.funcs"
import { MultiCheckboxField } from "./form-field/multi-checkbox-field"
import { ToasterConfig, ToasterService } from "angular2-toaster"
import { HtmlAreaField } from "./form-field/html-field"
import { MultiRadioButtonField } from "./form-field/multi-radio-button-field"

@Component({
  selector: "app-lead-dynamic-form",
  templateUrl: "lead-dynamic-form.component.html",
})
export class LeadDynamicFormComponent implements OnInit, OnChanges {
  @Input() contactId: string
  @Input() formId: string
  @Input() contactFields: any[]
  @Output() formSaved: EventEmitter<any> = new EventEmitter()

  private oQuestions: FormFieldBase<any>[]
  public isLoaded: Boolean = false

  leadDynamicForm: FormGroup
  payLoad = ""
  public model = {
    id: "",
    form_id: "",
    fields: [],
  }
  ifFieldExist = false
  // TOASTER

  toasterConfig: any
  toasterconfig: ToasterConfig = new ToasterConfig({
    positionClass: "toast-top-right",
    showCloseButton: true,
  })

  constructor(
    private toasterService: ToasterService,
    private contactService: ContactService,
    private formService: FormService
  ) {}

  ngOnInit() {
    if (this.formId == "-1") {
      // get the forms ids from fields
      this.loadCompletedForm()
    } else {
      this.getForm(this.formId)
    }
  }

  ngOnChanges(changes) {
    if (!changes["formId"].isFirstChange()) {
      this.getForm(this.formId)
    }
  }

  getForm(id) {
    this.isLoaded = false
    this.formService.getForm(id).subscribe(
      res => {
        if ((res.success = "true")) {
          // debugger
          const fields = res.data.fields
          if (fields.length > 0) {
            this.ifFieldExist = true
            this.generateQuestions(fields).subscribe(questions => {
              this.leadDynamicForm = this.toFormGroup(questions)
              // console.log(this.oQuestions)
              this.isLoaded = true
            })
          } else {
            this.isLoaded = true
          }
        }
      },
      err => {
        console.log(err)
        debugger
        this.toasterService.pop("error", null, err.error)
      }
    )
  }
  loadCompletedForm() {
    this.isLoaded = false
    // console.log(this.contactFields);
    if (this.contactFields.length > 0) {
      this.ifFieldExist = true
      this.generateQuestions(this.contactFields).subscribe(questions => {
        this.leadDynamicForm = this.toFormGroup(questions)
        this.isLoaded = true
        // console.log(this.oQuestions)
      })
    } else {
      this.isLoaded = true
    }
  }

  toFormGroup(questions: FormFieldBase<any>[]): FormGroup {
    const group: any = {}
    if (questions && questions.length > 0) {
      questions.forEach(question => {
        if (question.controlType === "multicheckbox") {
          // Ensure we're passing an array
          const initialValue = Array.isArray(question.value)
            ? question.value
            : typeof question.value === "string"
            ? question.value
                .split(/,|\n/)
                .map(v => v.trim())
                .filter(v => v !== "")
            : []
          group[question.key] = new FormControl(initialValue)
        } else {
          group[question.key] = question.required
            ? new FormControl(question.value || "", Validators.required)
            : new FormControl(question.value || "")
        }
      })
    }
    return new FormGroup(group)
  }

  generateQuestions(fields) {
    return Observable.create(observer => {
      this.oQuestions = []
      for (let i = 0; i < fields.length; i++) {
        // console.log(_.lowerCase(fields[i]['field']['input_type']));
        // console.log("Field : "+JSON.stringify(fields[i]));

        if (_.lowerCase(fields[i]["field"]["input_type"]) === "text") {
          this.oQuestions.push(
            new TextboxField({
              key: fields[i].field.id,
              label: fields[i].field.name,
              value: this.checkFieldValue(fields[i].field.id),
              required: fields[i].field.is_required,
              order: i,
            })
          )
        } else if (_.lowerCase(fields[i]["field"]["input_type"]) === "select") {
          const fieldsValues = fields[i]["field"]["values"]
            ? fields[i]["field"]["values"]
            : []
          this.oQuestions.push(
            new DropdownField({
              key: fields[i].field.id,
              label: fields[i].field.name,
              value: this.checkFieldValue(fields[i].field.id),
              options: this.setDropDownOptions(fieldsValues), // key => value
              order: i,
            })
          )
        } else if (
          _.lowerCase(fields[i]["field"]["input_type"]) === "checkbox"
        ) {
          this.oQuestions.push(
            new CheckboxField({
              key: fields[i].field.id,
              label: fields[i].field.name,
              value: this.checkFieldCheckBoxValue(fields[i].field.id),
              required: false,
              order: i,
            })
          )
        } else if (
          _.lowerCase(fields[i]["field"]["input_type"]) === "checkbox multiple"
        ) {
          console.log(_.lowerCase(fields[i]["field"]["input_type"]));
          const fieldValue = this.checkFieldCheckBoxValue(fields[i].field.id)
          console.log("Initial value for multi-checkbox:", fieldValue)

          this.oQuestions.push(
            new MultiCheckboxField({
              key: fields[i].field.id,
              label: fields[i].field.name,
              value: fieldValue, // This should now be an array
              required: false,
              order: i,
              multipleValues: fields[i].field.display_values
                ? fields[i].field.display_values.split(",").map(v => v.trim())
                : [],
            })
          )
        } else if (
          _.lowerCase(fields[i]["field"]["input_type"]) === "textarea"
        ) {
          this.oQuestions.push(
            new TextAreaField({
              key: fields[i].field.id,
              label: fields[i].field.name,
              value: this.checkFieldValue(fields[i].field.id),
              required: fields[i].field.is_required,
              order: i,
            })
          )
        } else if (_.lowerCase(fields[i]['field']['input_type']) === 'radio') {
          console.log('Creating multi-radio field:', fields[i]);
          this.oQuestions.push(
            new MultiRadioButtonField({
              key: fields[i].field.id,
              label: fields[i].field.name,
              value: this.checkFieldValue(fields[i].field.id),
              required: fields[i].field.is_required,
              order: i,
              multipleValues: fields[i].field.display_values ? 
                             fields[i].field.display_values.split(',').map(v => v.trim()) : 
                             []
            })
          )
        } else if (_.lowerCase(fields[i]["field"]["input_type"]) === "html") {
          this.oQuestions.push(
            new HtmlAreaField({
              key: fields[i].field.id,
              label: fields[i].field.name,
              value: fields[i].field.display_values,
              display_values: this.checkFieldValue(
                fields[i].field.display_values
              ),
              required: fields[i].field.is_required,
              order: i,
            })
          )
        }
      }
      observer.next(this.oQuestions.sort((a, b) => a.order - b.order))
    })
  }

  setDropDownOptions(valuesObject) {
    const dropdownValues = Object.keys(valuesObject).map(data => [
      data,
      valuesObject[data],
    ])

    return dropdownValues.map(function (l, n) {
      return {
        key: l[0],
        value: l[1],
      }
    })
  }

  checkFieldCheckBoxValue(id) {
    console.log("-------- Start checkFieldCheckBoxValue --------")
    console.log("Checking field ID:", id)

    if (this.contactId && this.contactId !== "") {
      console.log("ContactId exists:", this.contactId)

      for (let i = 0; i < this.contactFields.length; i++) {
        if (this.contactFields[i]["id"] === id) {
          const field = this.contactFields[i]
          const fieldValue = field["value"]
          const isMultiCheckbox =
            field["field"] &&
            field["field"]["input_type"] &&
            field["field"]["input_type"].toLowerCase() === "checkbox_multiple"

          console.log("Found matching field:", field)
          console.log("Field value:", fieldValue)
          console.log("Is multiple checkbox?", isMultiCheckbox)

          // For multiple checkboxes
          if (isMultiCheckbox && fieldValue) {
            const result =
              typeof fieldValue === "string"
                ? fieldValue
                    .split(/,|\n/)
                    .map(v => v.trim())
                    .filter(v => v !== "")
                : fieldValue

            console.log("Multiple checkbox result:", result)
            return result
          }

          // For single checkboxes (keeping original logic)
          const result = fieldValue === "Yes"
          console.log("Single checkbox result:", result)
          return result
        }
      }
      console.log("No matching field found, returning false")
      return false // Default for single checkbox
    }
    console.log("No contactId, returning false")
    return false // Default when no contactId
  }

  checkFieldValue(id) {
    // console.log(JSON.stringify(this.contactFields), id);
    if (this.contactId && this.contactId != "") {
      for (var i = 0; i < this.contactFields.length; i++) {
        if (this.contactFields[i]["id"] == id) {
          return this.contactFields[i]["value"]
        }
      }
      return ""
    } else {
      return ""
    }
  }

  onSubmit() {
    this.isLoaded = false
    // Reset fields array before generating new one
    this.model.fields = []

    console.log("Form Values:", this.leadDynamicForm.value) // Add this

    this.generateFieldsArray(this.leadDynamicForm.value)

    console.log("Generated Model:", this.model) // Add this

    if (this.contactId != "") {
      this.model.id = this.contactId
    } else {
      delete this.model.id
    }

    this.model.form_id = ""

    // Log final model before submission
    console.log("Final Model:", this.model) // Add this

    this.contactService.saveForm(this.model).subscribe((res: any) => {
      if (res.success == "true") {
        this.isLoaded = true
        this.pop("success", "", "Contact Successfully Submited")
        this.formSaved.emit(res.data.id)
      } else {
        this.pop("error", "", "Form Submission Failed")
      }
    })
  }

  generateFieldsArray(formValue) {
    // Clear existing fields
    this.model.fields = []

    console.log("Processing formValue:", formValue)

    for (const key in formValue) {
      if (formValue.hasOwnProperty(key)) {
        // Find the corresponding question
        const question = this.oQuestions.find(q => q.key === key)

        // Skip if value is null or undefined
        if (formValue[key] === null || formValue[key] === undefined) {
          continue
        }

        if (question) {
          console.log(question.controlType);
          switch (question.controlType) {
            case "multicheckbox":
              
              // Handle multiple checkboxes
              const selectedValues = Array.isArray(formValue[key])
                ? formValue[key]
                : []
              if (selectedValues.length > 0) {
                this.model.fields.push({
                  id: key,
                  value: selectedValues.join(","),
                })
              }
              break

            case "checkbox":
              // Handle single checkbox
              this.model.fields.push({
                id: key,
                value: formValue[key] ? "Yes" : "No",
              })
              break

            default:
              // Handle all other field types
              // Only add if value is not empty
              if (formValue[key] !== "") {
                this.model.fields.push({
                  id: key,
                  value: formValue[key],
                })
              }
              break
          }
        }
      }
    }

    console.log("Generated fields:", this.model.fields)
  }

  pop(type, title, text) {
    this.toasterService.pop(type, title, text)
  }
}
