<div class="preloader">
  <div class="preloader-progress">
    <div class="whirl standard spinnermargin"></div>
    <div class="preloader-progress-bar"></div>
    <div class="text-center mt-3">
      <p>Authenticating...</p>
      <small class="text-muted"
        >Please wait while we complete the authentication process</small
      >
    </div>
  </div>
</div>
