import { Injectable } from "@angular/core"
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from "@angular/common/http"
import { environment } from "@env/environment"
import { Observable, throwError } from "rxjs"
import { catchError, timeout, retry, map } from "rxjs/operators"
import * as _ from "lodash"
export enum ErrorType {
  Network = "NETWORK_ERROR",
  Timeout = "TIMEOUT_ERROR",
  NotFound = "404_NOT_FOUND",
  Server = "SERVER_ERROR",
  Unauthorized = "UNAUTHORIZED",
  BadRequest = "BAD_REQUEST",
  Unknown = "UNKNOWN_ERROR",
  Conflict = "CONFLICT",
}

export interface ApiError {
  type: ErrorType
  message: string
  statusCode?: number
  timestamp: Date
  path?: string
  details?: any
}

@Injectable()
export class RequestLibraryService extends HttpClient {
  private baseUrl = environment.api_base_url
  private readonly TIMEOUT_DURATION = 20000 // 20 seconds
  private readonly MAX_RETRIES = 2
  private handleError(
    error: HttpErrorResponse,
    endpoint: string
  ): Observable<never> {
    let apiError: ApiError = {
      type: ErrorType.Unknown,
      message: "An unknown error occurred",
      timestamp: new Date(),
    }

    if (error.error instanceof ErrorEvent) {
      // Client-side or network error
      apiError = {
        type: ErrorType.Network,
        message:
          "Network error occurred. Please check your internet connection.",
        timestamp: new Date(),
        details: error.error.message,
      }
    } else {
      // Backend returned unsuccessful response code
      switch (error.status) {
        case 0:
          apiError = {
            type: ErrorType.Network,
            message:
              "Unable to connect to the server. Please check your internet connection.",
            timestamp: new Date(),
          }
          break
        case 400:
          apiError = {
            type: ErrorType.BadRequest,
            message: "Invalid request",
            statusCode: error.status,
            timestamp: new Date(),
            details: error.error,
          }
          break
        case 401:
          apiError = {
            type: ErrorType.Unauthorized,
            message: "Unauthorized access",
            statusCode: error.status,
            timestamp: new Date(),
          }
          // Optionally trigger logout or refresh token
          this.handleUnauthorized()
          break
        case 404:
          apiError = {
            type: ErrorType.NotFound,
            message: "Resource not found",
            statusCode: error.status,
            timestamp: new Date(),
            path: endpoint,
          }
          break
        case 500:
          apiError = {
            type: ErrorType.Server,
            message: "Internal server error",
            statusCode: error.status,
            timestamp: new Date(),
          }
          break
        default:
          apiError = {
            type: ErrorType.Unknown,
            message: `An error occurred: ${error.message}`,
            statusCode: error.status,
            timestamp: new Date(),
            details: error.error,
          }
      }
    }

    // Log error for debugging
    console.error("API Error:", apiError)

    // You can also send error to your error tracking service here
    // this.errorTrackingService.logError(apiError);

    return throwError(() => apiError)
  }

  private handleTimeout(): Observable<never> {
    const apiError: ApiError = {
      type: ErrorType.Timeout,
      message: "Request timed out. Please try again.",
      timestamp: new Date(),
    }
    return throwError(() => apiError)
  }

  private handleUnauthorized(): void {
    // Implement your unauthorized logic here
    // For example: redirect to login, clear tokens, etc.
    console.log("Handling unauthorized access")
  }

  public GetAll<T>(endPoint: string, qArgs?: {}): Observable<T> {
    const options: IRequestOptions = {}
    if (qArgs) {
      options.params = this.toHttpParams(qArgs)
    }

    return super.get<T>(this.prepUrl(endPoint), options).pipe(
      timeout(this.TIMEOUT_DURATION),
      retry(this.MAX_RETRIES),
      catchError(error => {
        if (error.name === "TimeoutError") {
          return this.handleTimeout()
        }
        return this.handleError(error, endPoint)
      })
    )
  }

  public BaseGet<T>(
    endPoint: string,
    options?: IRequestOptions
  ): Observable<T> {
    return super.get<T>(endPoint, options)
  }

  public Get<T>(endPoint: string, options?: IRequestOptions): Observable<T> {
    return super.get<T>(this.prepUrl(endPoint), options).pipe(
      timeout(this.TIMEOUT_DURATION),
      retry(this.MAX_RETRIES),
      catchError(error => {
        if (error.name === "TimeoutError") {
          return this.handleTimeout()
        }
        return this.handleError(error, endPoint)
      })
    )
  }

  public Post<T>(
    endPoint: string,
    params: Object,
    options?: IRequestOptions
  ): Observable<T> {
    return super.post<T>(this.prepUrl(endPoint), params, options).pipe(
      timeout(this.TIMEOUT_DURATION),
      retry(this.MAX_RETRIES),
      catchError(error => {
        if (error.name === "TimeoutError") {
          return this.handleTimeout()
        }
        return this.handleError(error, endPoint)
      })
    )
  }

  public Put<T>(
    endPoint: string,
    params: Object,
    options?: IRequestOptions
  ): Observable<T> {
    return super.put<T>(this.prepUrl(endPoint), params, options)
  }

  public Delete<T>(endPoint: string): Observable<boolean> {
    return super
      .delete<T>(this.prepUrl(endPoint), { observe: "response" })
      .pipe(
        timeout(this.TIMEOUT_DURATION),
        retry(this.MAX_RETRIES),
        map(response => response.status === 204), // Convert to boolean based on status code
        catchError(error => {
          if (error.name === "TimeoutError") {
            return this.handleTimeout()
          }

          // Special handling for common delete-specific scenarios
          if (error.status === 409) {
            return throwError(
              () =>
                ({
                  type: ErrorType.Conflict,
                  message: "Resource cannot be deleted due to conflict",
                  statusCode: error.status,
                  timestamp: new Date(),
                  path: endPoint,
                  details: error.error,
                } as ApiError)
            )
          }

          // Handle case where resource is already deleted
          if (error.status === 404) {
            // You might want to return true here if you consider "already deleted" as success
            // return of(true);
            return throwError(
              () =>
                ({
                  type: ErrorType.NotFound,
                  message: "Resource already deleted or not found",
                  statusCode: error.status,
                  timestamp: new Date(),
                  path: endPoint,
                } as ApiError)
            )
          }

          return this.handleError(error, endPoint)
        })
      )
  }

  public GetCSV(endPoint: string, qArgs?: {}) {
    const options: any = {}
    if (qArgs) {
      options.params = this.toHttpParams(qArgs)
      options.responseType = "text"
      options.observe = "response"
    }

    return super.get(this.prepUrl(endPoint), options).pipe(
      timeout(this.TIMEOUT_DURATION),
      map(this.toCSVResponse),
      catchError(error => {
        if (error.name === "TimeoutError") {
          return this.handleTimeout()
        }
        return this.handleError(error, endPoint)
      })
    )
  }

  private prepUrl(url: string) {
    return `${this.baseUrl}${url}`
  }
  private tojsonURI(json) {
    return JSON.stringify(json)
  }
  private toBooleanResponse(res: any) {
    if (res.status == 204) {
      return true
    } else {
      return false
    }
  }

  private toCSVResponse(res: any) {
    // console.log(res);
    let fileName = null
    const headers = res.headers
    const contentDisposition = headers.get("content-disposition") || ""
    if (contentDisposition) {
      const result = contentDisposition.split(";")[1].trim().split("=")[1]
      fileName = result.replace(/"/g, "")
    }

    if (res.status == 200 || res.status == 201 || res.status == 202) {
      const body = (<any>res).body
      return { data: body, filename: fileName }
    } else {
      return []
    }
  }

  private toHttpParams(params) {
    return Object.getOwnPropertyNames(params).reduce(
      (p, key) => p.set(key, this.tojsonURI(params[key])),
      new HttpParams()
    )
  }
}

export interface IRequestOptions {
  headers?: HttpHeaders
  observe?: "body"
  params?: HttpParams
  reportProgress?: boolean
  responseType?: "json"
  withCredentials?: boolean
  body?: any
}
