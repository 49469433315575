import { Component, Input, OnChanges, OnInit, SimpleChange } from '@angular/core';
import { Privilege } from '@decorator/index';
import { globalValues } from '@app/globals';
import { CampaignReportService, HelpersService, ToasterLibraryService } from '@service/index';
import { NgxPermissionsService } from 'ngx-permissions';
import { Logger } from '@service/global.funcs';

@Component({
  selector: 'app-sales-by-campaign-widget',
  templateUrl: './sales-by-campaign-widget.component.html',
  styleUrls: ['./sales-by-campaign-widget.component.scss'],
})

// @Privilege({
//   privilege: 'campaigns:can_get_campaigns_reports_sales_per_campaign'
// })
export class SalesByCampaignWidgetComponent implements OnInit, OnChanges {
  @Input() relativeDate: any;
  @Input() campaignData: any;
  @Input() widgetTitle = 'Sales by Campaign';
  @Input('chartType') chartType = 'pieChart';
  @Input('useTestData') useTestData = false;
  public salesByCampaignData = {
    labels: [],
    datasets: [{
      data: []
    }],
    totalCount: 0,
  };
  testData = {
    labels: ['2015-01-01', '2015-01-02', '2015-01-03', '2015-01-04', '2015-01-05', '2015-01-06', '2015-01-07'],
    datasets: [
      {
        data: [0, 2, 5, 0, 6, 3, 4]
      }
    ],
    totalCount: 7,
  };
  public salesByCampaignLoaded = false;

  constructor(
    private campaignReportService: CampaignReportService,
    private toasterLibrary: ToasterLibraryService,
    private permissionService: NgxPermissionsService,
    private helperService: HelpersService

  ) { }

  ngOnInit() {
    if (this.permissionService.hasPermission('visitors:can_get_visitors_reports_count')) {
      if(this.useTestData){
        this.salesByCampaignData = this.testData;
        this.salesByCampaignLoaded = true;
      }else{
        this.getSalesByCampaign();
      }
    }

  }

  ngOnChanges(changes: { [key: string]: SimpleChange }) {

    if (this.permissionService.hasPermission('visitors:can_get_visitors_reports_count')) {
      if (
        (changes['relativeDate'] && !changes['relativeDate'].isFirstChange()) ||
        (changes['campaignData'] && !changes['campaignData'].isFirstChange()) ||
        (changes['criteriaArray'] && !changes['criteriaArray'].isFirstChange())
      ) {
        if(this.useTestData){
          this.salesByCampaignData = this.testData;
          this.salesByCampaignLoaded = true;
        }else{
          this.getSalesByCampaign();
        }
      }
    }
  }

  getSalesByCampaign() {
    const criteriaArray: any = [
      {
        id: '',
        is_required: true,
        criterion: 'dateRange',
        value: {
          start_date: '',
          end_date: '',
          relative_date: this.relativeDate
        }
      }, {
        id: '',
        is_required: true,
        criterion: 'statusSubstatus',
        value: {
          ostat_id: 2,
          ss_id: 0
        }
      }
    ];

    if (this.campaignData && this.campaignData != "") {
      criteriaArray.push({
        'id': '',
        'value': { c_id: this.campaignData },
        'criterion': 'campaign',
        'is_required': true
      });
    }

    this.salesByCampaignLoaded = false;
    let aggregateIndex = 0;
    this.reInit();
    this.campaignReportService.getLeadsByCampaign(criteriaArray).subscribe(
      (res: any) => {
        if (res.success === 'true') {
          let fifthOptionCount = 0;
          res.data.forEach((campaign, index) => {
            let cat = campaign.category;
            if (index < globalValues.pie_chart_slices) {
              if (!this.salesByCampaignData.labels.includes(cat)) {
                this.salesByCampaignData.labels.push(cat);
                this.salesByCampaignData.datasets[0].data.push(campaign.number_of_occurrences);
              } else {
                aggregateIndex = this.salesByCampaignData.labels.indexOf(cat);
                Logger().info('getSalesByCampaign', 'pie chart data', 'aggregate index', aggregateIndex);
                Logger().info('getSalesByCampaign', 'pie chart data', 'channel', campaign);

                this.salesByCampaignData.datasets[0].data[aggregateIndex] =
                  parseInt(this.salesByCampaignData.datasets[0].data[aggregateIndex], 10) +
                  parseInt(campaign.number_of_occurrences, 10);

                Logger().info(
                  'getSalesByCampaign',
                  'pie chart data',
                  'new channel',
                  this.salesByCampaignData.datasets[0].data[aggregateIndex]
                );
              }
            } else {
              fifthOptionCount = fifthOptionCount + parseInt(campaign.number_of_occurrences, 10);
            }
            this.salesByCampaignData.totalCount =
              this.salesByCampaignData.totalCount + parseInt(campaign.number_of_occurrences, 10);
          });
          if (fifthOptionCount > 0) {
            this.salesByCampaignData.labels.push('All Other');
            this.salesByCampaignData.datasets[0].data.push(fifthOptionCount);
          }
        }
        this.salesByCampaignLoaded = true;
      },
      err => {
        this.toasterLibrary.error(null, err.error.message);
      }
    );
  }

  exportChartData() {
    const orgId = localStorage.getItem("account_id") ?? "0";

    this.salesByCampaignLoaded = false
    this.helperService.chartCSVDownload(this.salesByCampaignData, `[${orgId}] ${this.widgetTitle}`)

    setTimeout(() => {
      this.salesByCampaignLoaded = true
    }, 500);
  }

  private reInit() {
    this.salesByCampaignData = {
      labels: [],
      datasets: [{
        data: []
      }],
      totalCount: 0,
    };
  }
}
