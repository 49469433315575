<ng-template [ngxPermissionsOnly]="['contacts:can_get_contacts_reports_count_per_city']">
  <div class="box-row panel panel-default">
    <div class="panel-heading">
      <div class="d-flex align-items-center justify-content-space-between">
        <h4>{{widgetTitle}}</h4>
        <!-- <div *ngIf="patientsMapLoaded" class="refresh-listing-icon" title="Export" (click)="exportChartData()">
          <i class="fa fa-cloud-download fa-lg" aria-hidden="true"></i>
        </div> -->
      </div>
    </div>
    <div class="panel-body text-center">
      <div *ngIf="!patientsMapLoaded" class="whirl standard spinnermargin"></div>
      <ng-template [ngIf]="patientsMapLoaded">
        <app-map [seriesData]=seriesData [markersData]=markersData></app-map>
      </ng-template>
    </div>
  </div>
</ng-template>