<ng-template [ngxPermissionsOnly]="['statuses:can_get_statuses_reports_sales_pipeline']">
  <div class="box-row panel panel-default">
    <div class="panel-heading">
      <div class="d-flex align-items-center justify-content-space-between">
        <h4>{{widgetTitle}}</h4>
        <div *ngIf="isSalesPipelineChartLoaded" class="refresh-listing-icon" title="Export"
          (click)="exportChartData()">
          <i class="fa fa-cloud-download fa-lg" aria-hidden="true"></i>
        </div>
      </div>
    </div>
    <div class="panel-body text-center">
      <div *ngIf="!isSalesPipelineChartLoaded" class="whirl standard spinnermargin"></div>
      <ng-template [ngIf]="isSalesPipelineChartLoaded">
        <!-- <bar-chart [barData]=salesPipelineChartData [totalCount]="salesPipelineChartData.totalPipelineSales" chartType="verticalBar"
          chartFor="normalSize" [chartDisableAspectRatio]="chartAspectRatio"></bar-chart> -->          
          <stacked-bar-chart [stackedBarData]="stackedBarChartData" [stackedBarHeight]="250"></stacked-bar-chart>
      </ng-template>
    </div>
  </div>
</ng-template>