import { Component, Input, OnChanges, OnInit, SimpleChange } from '@angular/core';
import { Privilege } from '@decorator/index';
import { globalValues } from '@app/globals';
import { CampaignReportService, ToasterLibraryService, HelpersService } from '@service/index';
import { NgxPermissionsService } from 'ngx-permissions';
import { Logger } from '@service/global.funcs';

@Component({
  selector: 'app-lead-by-campaign-widget',
  templateUrl: './lead-by-campaign-widget.component.html',
  styleUrls: ['./lead-by-campaign-widget.component.scss']
})

// @Privilege({
//   privilege: 'campaigns:can_get_campaigns_reports_contact_per_campaign'
// })
export class LeadByCampaignWidgetComponent implements OnInit, OnChanges {
  @Input() relativeDate = 'Last 90 Days';
  @Input() widgetTitle = 'Leads by Campaign';
  @Input() channel = '';
  @Input() campaignData: any;
  @Input('chartType') chartType = 'pieChart';
  @Input('useTestData') useTestData = false;
  public leadsByCampaignLoaded = false;
  public leadsByCampaignData = {
    labels: [],
    datasets: [
      {
        data: []
      }
    ],
    totalCount: 0
  };
  testData = {
    labels: ['2015-01-01', '2015-01-02', '2015-01-03', '2015-01-04', '2015-01-05', '2015-01-06', '2015-01-07'],
    datasets: [
      {
        data: [0, 2, 5, 0, 6, 3, 4]
      }
    ],
    totalCount: 7,
  };
  constructor(
    private campaignReportService: CampaignReportService,
    private toasterLibrary: ToasterLibraryService,
    private permissionService: NgxPermissionsService,
    private helperService: HelpersService
  ) { }

  ngOnInit() {
    if (this.permissionService.hasPermission('campaigns:can_get_campaigns_reports_contact_per_campaign')) {
      if(this.useTestData){
        this.leadsByCampaignData = this.testData;
        this.leadsByCampaignLoaded = true;
      }else{
        this.getLeadsByCampaign();
      }
    }
  }
  ngOnChanges(changes: { [key: string]: SimpleChange }) {

    if (this.permissionService.hasPermission('campaigns:can_get_campaigns_reports_contact_per_campaign')) {
      if (
        (changes['relativeDate'] && !changes['relativeDate'].isFirstChange()) ||
        (changes['campaignData'] && !changes['campaignData'].isFirstChange()) ||
        (changes['criteriaArray'] && !changes['criteriaArray'].isFirstChange())
      ) {
        if(this.useTestData){
          this.leadsByCampaignData = this.testData;
          this.leadsByCampaignLoaded = true;
        }else{
          this.getLeadsByCampaign();
        }
      }
    }
  }
  getLeadsByCampaign() {
    const criteriaArray: any = [
      {
        id: '',
        is_required: true,
        criterion: 'dateRange',
        value: {
          start_date: '',
          end_date: '',
          relative_date: this.relativeDate
        }
      },
      {
        id: '',
        is_required: true,
        criterion: 'channel',
        value: {
          c_channel: this.channel
        }
      }
    ];

    if (this.campaignData && this.campaignData != "") {
      criteriaArray.push({
        id: '',
        value: { c_id: this.campaignData },
        criterion: 'campaign',
        is_required: true
      });
    }
    this.leadsByCampaignLoaded = false;
    let aggregateIndex = 0;
    this.reInit();
    this.campaignReportService.getLeadsByCampaign(criteriaArray).subscribe(
      (res: any) => {
        if (res.success === 'true') {
          let fifthOptionCount = 0;
          res.data.forEach((campaign, index) => {
            let cat = campaign.category;

            // if (typeof cat === 'string' && cat.length >= 35) {
            //   cat = cat.substr(0, 35) + '...';
            // }
            if (index < globalValues.pie_chart_slices) {
              if (!this.leadsByCampaignData.labels.includes(cat)) {
                this.leadsByCampaignData.labels.push(cat);
                this.leadsByCampaignData.datasets[0].data.push(campaign.number_of_occurrences);
              } else {
                aggregateIndex = this.leadsByCampaignData.labels.indexOf(cat);
                Logger().info('getLeadsByCampaign', 'pie chart data', 'aggregate index', aggregateIndex);
                Logger().info('getLeadsByCampaign', 'pie chart data', 'channel', campaign);

                this.leadsByCampaignData.datasets[0].data[aggregateIndex] =
                  parseInt(this.leadsByCampaignData.datasets[0].data[aggregateIndex], 10) +
                  parseInt(campaign.number_of_occurrences, 10);

                Logger().info(
                  'getLeadsByCampaign',
                  'pie chart data',
                  'new channel',
                  this.leadsByCampaignData.datasets[0].data[aggregateIndex]
                );
              }
            } else {
              fifthOptionCount = fifthOptionCount + parseInt(campaign.number_of_occurrences, 10);
            }
            this.leadsByCampaignData.totalCount =
              this.leadsByCampaignData.totalCount + parseInt(campaign.number_of_occurrences, 10);
          });
          if (fifthOptionCount > 0) {
            this.leadsByCampaignData.labels.push('All Other');
            this.leadsByCampaignData.datasets[0].data.push(fifthOptionCount);
          }
        }
        this.leadsByCampaignLoaded = true;
      },
      err => {
        this.toasterLibrary.error(null, err.error.message);
      }
    );
  }

  exportChartData() {
    const orgId = localStorage.getItem("account_id") ?? "0";

    this.leadsByCampaignLoaded = false
    this.helperService.chartCSVDownload(this.leadsByCampaignData, `[${orgId}] ${this.widgetTitle}`)

    setTimeout(() => {
      this.leadsByCampaignLoaded = true
    }, 500);
  }

  private reInit() {
    this.leadsByCampaignData = {
      labels: [],
      datasets: [
        {
          data: []
        }
      ],
      totalCount: 0
    };
  }
}
