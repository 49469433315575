import { Component, Input, OnChanges, OnInit, SimpleChange } from '@angular/core';
import { Privilege } from '@decorator/index';
import { StatusService, StatusReportService, ToasterLibraryService, HelpersService } from '@service/index';
import { NgxPermissionsService } from 'ngx-permissions';
import { Logger } from '@service/global.funcs';
import * as _ from 'lodash';
@Component({
  selector: 'app-status-sales-pipeline-widget',
  templateUrl: './status-sales-pipeline-widget.component.html',
  styleUrls: ['./status-sales-pipeline-widget.component.scss']
})

// @Privilege({
//   privilege: 'statuses:can_get_statuses_reports_sales_pipeline'
// })
export class StatusSalesPipelineWidgetComponent implements OnInit, OnChanges {
  @Input() relativeDate: any;
  @Input() chartAspectRatio: false;
  @Input() widgetTitle = 'Sales Pipeline';
  @Input() campaignData: any;
  public subStatusesLoaded = false;

  public subStatusesArray: any = [];
  public stackedBarChartData = [
    {
      data: [],
      label: this.widgetTitle,
      color: '#1fafff'
    }
  ];
  private total_number_occurrence = 10;

  public isSalesPipelineChartLoaded = false;
  constructor(
    private statusReportService: StatusReportService,
    private statusService: StatusService,
    private toasterLibrary: ToasterLibraryService,
    private permissionService: NgxPermissionsService,
    private helperService: HelpersService
  ) { }

  ngOnInit() {
    if (this.permissionService.hasPermission('statuses:can_get_statuses_reports_sales_pipeline')) {
      this.getAllStatus();
    }
  }

  ngOnChanges(changes: { [key: string]: SimpleChange }) {

    if (this.permissionService.hasPermission('statuses:can_get_statuses_reports_sales_pipeline')) {
      if (
        (changes['relativeDate'] && !changes['relativeDate'].isFirstChange()) ||
        (changes['campaignData'] && !changes['campaignData'].isFirstChange()) ||
        (changes['criteriaArray'] && !changes['criteriaArray'].isFirstChange())
      ) {
        this.getAllStatus();
      }
    }
  }

  getSalesPipelineChart() {
    const criteriaArray: any = [
      {
        id: '',
        is_required: true,
        criterion: 'dateRange',
        value: {
          start_date: '',
          end_date: '',
          relative_date: this.relativeDate
        }
      },
      {
        id: '',
        is_required: true,
        criterion: 'statusSubstatus',
        value: {
          ostat_id: 1,
          ss_id: 0
        }
      }
    ];

    if (this.campaignData && this.campaignData != "") {
      criteriaArray.push({
        'id': '',
        'value': { c_id: this.campaignData },
        'criterion': 'campaign',
        'is_required': true
      });
    }

    this.isSalesPipelineChartLoaded = false;
    this.reInit();
    this.statusReportService.getSalesPipeline(criteriaArray).subscribe(
      (res: any) => {
        if (res.success === 'true') {
          const forAggregation = res.data.slice(0);
          // Get the complete pipeline, not the other statuses, and prepopulate the data.
          const afterAggregation = this.subStatusesArray
            .filter(function (substatus) {
              return substatus.parent_status.name === 'Open';
            })
            .map(function (substatus) {
              return { description: substatus.name, number_of_occurrences: 0 };
            });

          // Aggregate the data so we don't have duplicate bars for each category.
          forAggregation.forEach((record, index, theArray) => {
            if (undefined !== afterAggregation && afterAggregation.length > 0) {
              const idx = afterAggregation.findIndex(item => item.description === record.description);
              if (idx !== -1) {
                afterAggregation[idx].number_of_occurrences =
                  parseInt(afterAggregation[idx].number_of_occurrences, 10) +
                  parseInt(record.number_of_occurrences, 10);
              } else {
                afterAggregation.push(record);
              }
            } else {
              afterAggregation.push(record);
            }
          });

          Logger().info('getSalesPipelineChart', 'afterAggregation::', afterAggregation);
          const afterAggregationTop10 = _.chain(afterAggregation)
            .sortBy(function (item) { return item.number_of_occurrences * -1; })
            .slice(0, this.total_number_occurrence)
            .value();

          Logger().info('getSalesPipelineChart', 'afterAggregationTop10::', afterAggregationTop10);

          afterAggregationTop10.forEach(record => {
            if (record.description && record.number_of_occurrences > 0) {
              this.stackedBarChartData[0].data.push([record.description, parseInt(record.number_of_occurrences, 10)]);
            }
          });
          // Logger().info('getSalesPipelineChart', 'this.stackedBarChartData', this.stackedBarChartData);

          this.isSalesPipelineChartLoaded = true;
        }
      },
      err => {
        this.toasterLibrary.error(null, err.error.message);
      }
    );
  }

  getAllStatus() {
    this.subStatusesLoaded = false;
    this.statusService.getAllStatuses('stage', 'ASC').subscribe(
      (res: any) => {
        if (res.success === 'true') {
          this.subStatusesArray = res.data;
          this.subStatusesLoaded = true;
          this.getSalesPipelineChart();
        }
      },
      err => {
        this.toasterLibrary.error(null, err.error.message);
      }
    );
  }

  exportChartData() {
    const orgId = localStorage.getItem("account_id") ?? "0";
    console.log(typeof(this.stackedBarChartData));

    this.subStatusesLoaded = false
    this.helperService.chartCSVDownload(this.stackedBarChartData[0], `[${orgId}] Sales Pipeline`)

    setTimeout(() => {
      this.subStatusesLoaded = true
    }, 500);
  }

  private reInit() {
    this.stackedBarChartData = [
      {
        data: [],
        label: this.widgetTitle,
        color: '#1fafff'
      }
    ];
  }
}
