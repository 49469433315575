import { Component, OnInit, OnDestroy } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { auth0Config } from "@service/auth/auth0.config"
import * as _ from "lodash"
import {
  CustomerOnboardService,
  CustomerOnboardHelperService,
  CustomerOnboardRedirectService,
  ToasterLibraryService,
  AuthService,
} from "@service/index"
import { ToasterConfig } from "angular2-toaster"
import { AuthLogoutService } from "@service/auth/auth-logout.service"

// Interfaces
interface CompanyInfoModel {
  name: string
  username: string
  first_name: string
  last_name: string
  email: string
  id: string
  user_id: string
  sub: string
}

interface ApiResponse {
  success: string
  data?: CustomerData
  err?: string
}

interface CustomerData {
  id: string
  user_id: string
}

interface Auth0Payload {
  email?: string
  firstname?: string
  lastname?: string
  username?: string
}

@Component({
  selector: "app-company-info-page",
  templateUrl: "./company-info-page.component.html",
  styleUrls: ["./company-info-page.component.scss"],
})
export class CompanyInfoPageComponent implements OnInit, OnDestroy {
  // Auth0 related properties
  public auth0_session_token: string = ""
  public auth0_state: string = ""
  public auth0IncomingData: {
    mo_req_payload?: Auth0Payload
    sub?: string
    exp?: number
    iat?: number
  } = {}

  // UI state properties
  public isSaving = false
  public isLoading = false
  public wrongSubmission = false
  public loaded = false
  public loggedIn = false
  public loadingError: string | null = null

  // Form model
  public model: CompanyInfoModel = {
    name: "",
    username: "",
    first_name: "",
    last_name: "",
    email: "",
    id: "",
    user_id: "0",
    sub: "",
  }

  // Form validation
  public formErrors: Record<string, boolean> = {
    name: false,
    first_name: false,
    last_name: false,
    email: false,
    company_suite: false,
  }

  // Company suite selection
  public selectedAppVersion: string = ""

  // Constants
  private readonly REDIRECT_TIMEOUT = 10000 // 10 seconds
  private readonly WARNING_BEFORE_EXPIRY = 60000 // 1 minute warning
  private readonly _auth0_domain = auth0Config.domain

  // Session management
  private sessionExpiryTimer?: NodeJS.Timeout
  private sessionWarningTimer?: NodeJS.Timeout

  // Toaster configuration
  public toasterconfig: ToasterConfig = new ToasterConfig({
    positionClass: "toast-bottom-right",
    showCloseButton: true,
    timeout: 5000,
  })

  constructor(
    private _route: ActivatedRoute,
    private router: Router,
    private customerOnboardService: CustomerOnboardService,
    private customerOnboardHelperService: CustomerOnboardHelperService,
    private customerOnboardRedirectService: CustomerOnboardRedirectService,
    private toasterLibrary: ToasterLibraryService,
    private authService: AuthService,
    private authLogout: AuthLogoutService
  ) {
    this._route.queryParams.subscribe(params => {
      this.auth0_session_token = params["session_token"] || ""
      this.auth0_state = params["state"] || ""
    })
  }

  async ngOnInit() {
    try {
      // Validate required parameters
      if (!this.auth0_session_token || !this.auth0_state) {
        throw new Error("Missing required authentication parameters")
      }

      this.isLoading = true

      // Validate incoming token
      this.auth0IncomingData =
        await this.customerOnboardHelperService.validateIncomingToken(
          this.auth0_session_token
        )

      // Process incoming data
      this.processIncomingData()

      // Setup session expiration
      this.setupSessionExpiration()

      // Check existing login state
      const org_id = localStorage.getItem("account_id")
      const user_id = localStorage.getItem("User")

      if (org_id && user_id) {
        this.loggedIn = true
        this.model.id = org_id
        this.model.user_id = user_id
        await this.redirectToAuth0(this.model)
      } else {
        this.loggedIn = true
        await this.customerCheck()
      }
    } catch (error) {
      this.handleError(error as Error)
    } finally {
      this.isLoading = false
    }
  }

  ngOnDestroy() {
    if (this.sessionExpiryTimer) clearTimeout(this.sessionExpiryTimer)
    if (this.sessionWarningTimer) clearTimeout(this.sessionWarningTimer)
  }

  private processIncomingData() {
    if (this.auth0IncomingData?.mo_req_payload) {
      const payload = this.auth0IncomingData.mo_req_payload

      this.model.email = payload.email || ""
      this.model.first_name = payload.firstname || ""
      this.model.last_name = payload.lastname || ""
      this.model.username = payload.username || ""
      this.model.sub = this.auth0IncomingData.sub || ""

      this.loaded = true
    }
  }

  private setupSessionExpiration() {
    if (this.auth0IncomingData?.exp && this.auth0IncomingData?.iat) {
      const sessionExpTime =
        (this.auth0IncomingData.exp - this.auth0IncomingData.iat) * 1000

      if (sessionExpTime > this.WARNING_BEFORE_EXPIRY) {
        this.sessionWarningTimer = setTimeout(() => {
          this.toasterLibrary.warning(
            null,
            "Your session will expire in 1 minute"
          )
        }, sessionExpTime - this.WARNING_BEFORE_EXPIRY)
      }

      this.sessionExpiryTimer = setTimeout(() => {
        this.toasterLibrary.error(null, "Your session has expired")
        this.router.navigate(["/logout"])
      }, sessionExpTime)
    }
  }

  private validateForm(): boolean {
    this.formErrors = {
      name: !this.model.name?.trim(),
      first_name: !this.model.first_name?.trim(),
      last_name: !this.model.last_name?.trim(),
      email:
        !this.model.email?.trim() ||
        !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.model.email),
      company_suite: !this.selectedAppVersion,
    }
    return !Object.values(this.formErrors).some(error => error)
  }

  public async onSubmit() {
    if (this.isSaving) return

    if (!this.validateForm()) {
      this.wrongSubmission = true
      this.toasterLibrary.error(
        null,
        "Please fill all required fields correctly"
      )
      return
    }

    this.isSaving = true
    this.wrongSubmission = false

    try {
      const response = (await this.customerOnboardService
        .createCustomer(this.model)
        .toPromise()) as ApiResponse

      if (response.success === "true" && response.data) {
        this.model.id = response.data.id
        this.model.user_id = response.data.user_id
        this.authService.appendUserId(this.model.user_id, this.model.id, null)
        await this.redirectToAuth0(this.model)
      } else {
        this.toasterLibrary.error(
          null,
          response.err || "Unknown error occurred"
        )
      }
    } catch (error) {
      this.handleError(error as Error)
    } finally {
      this.isSaving = false
    }
  }

  public async customerCheck() {
    if (!this.model.email) {
      this.wrongSubmission = true
      return
    }

    this.isLoading = true
    this.loadingError = null

    try {
      const response = (await this.customerOnboardService
        .checkCustomer(this.model)
        .toPromise()) as ApiResponse

      if (response.success === "true" && response.data) {
        this.model.id = response.data.id
        this.model.user_id = response.data.user_id
        this.authService.appendUserId(this.model.user_id, this.model.id, null)
        await this.redirectToAuth0(this.model)
      } else if (response.success) {
        this.loggedIn = false
        this.toasterLibrary.success("Welcome To Marketing Optimizer!", "Please fill out the form to complete the onboarding process.")
      } else {
        this.loggedIn = false
        if (response.err) {
          this.toasterLibrary.error(null, response.err)
        }
      }
    } catch (error) {
      this.handleError(error as Error)
      this.loggedIn = false
    } finally {
      this.isLoading = false
      this.loaded = true
    }
  }

  public setAppVersion(appVersion: string) {
    if (appVersion) {
      localStorage.setItem("appVersion", appVersion)
      this.selectedAppVersion = appVersion
    }
  }

  private async redirectToAuth0(resOrgData: CompanyInfoModel) {
    try {
      const newToken = await this.customerOnboardHelperService.generateNewToken(
        this.auth0_state,
        this.auth0IncomingData,
        {
          mo_organization_id: resOrgData.id,
          mo_user_id: resOrgData.user_id,
          mo_user_name: resOrgData.name,
        }
      )

      this.auth0IncomingData =
        await this.customerOnboardHelperService.validateIncomingToken(newToken)

      const url = `https://${this._auth0_domain}/continue?state=${this.auth0_state}&mo_token=${newToken}`

      const timeoutPromise = new Promise((_, reject) =>
        setTimeout(
          () => reject(new Error("Redirect timeout")),
          this.REDIRECT_TIMEOUT
        )
      )

      await Promise.race([
        new Promise<void>(resolve => {
          window.location.href = url
          setTimeout(resolve, 1000) // Give browser time to start redirect
        }),
        timeoutPromise,
      ])
    } catch (error) {
      this.handleError(error as Error)
    }
  }

  private handleError(error: Error) {
    console.error("Error:", error)
    this.loadingError = error.message || "An unexpected error occurred"
    this.toasterLibrary.error(null, this.loadingError)
    if (
      error.message.includes("authentication") ||
      error.message.includes("session")
    ) {
      this.router.navigate(["/logout"])
    }
  }
}
