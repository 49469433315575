import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChange,
} from "@angular/core"
import { Privilege } from "@decorator/index"
import { HelpersService, ToasterLibraryService, UserReportService } from "@service/index"
import { NgxPermissionsService } from "ngx-permissions"
import { Logger } from "@service/global.funcs"

@Component({
  selector: "app-new-sales-by-rep-widget",
  templateUrl: "./new-sales-by-rep-widget.component.html",
  styleUrls: ["./new-sales-by-rep-widget.component.scss"],
})

// @Privilege({
//   privilege: 'users:can_get_users_reports_sales'
// })
export class NewSalesByRepWidgetComponent implements OnInit, OnChanges {
  @Input() relativeDate: any
  @Input() campaignData: any
  @Input() role_id = ""
  @Input() widgetTitle = "New Sales by Rep"
  public stackedBarChartData = [
    {
      data: [],
      label: this.widgetTitle,
      color: "#e88b1b",
    },
  ]
  public chartDataLoaded = false
  constructor(
    private userReportService: UserReportService,
    private toasterLibrary: ToasterLibraryService,
    private permissionService: NgxPermissionsService,
    private helperService: HelpersService
  ) {}

  ngOnInit() {
    if (
      this.permissionService.hasPermission("users:can_get_users_reports_sales")
    ) {
      this.getContactsByUser()
    }
  }

  ngOnChanges(changes: { [key: string]: SimpleChange }) {
    if (
      this.permissionService.hasPermission("users:can_get_users_reports_sales")
    ) {
      if (
        (changes["relativeDate"] && !changes["relativeDate"].isFirstChange()) ||
        (changes["campaignData"] && !changes["campaignData"].isFirstChange()) ||
        (changes["criteriaArray"] && !changes["criteriaArray"].isFirstChange())
      ) {
        this.getContactsByUser()
      }
    }
  }
  getContactsByUser() {
    const criteriaArray: any = [
      {
        id: "",
        is_required: true,
        criterion: "statusSubstatus",
        value: {
          ostat_id: 2,
          ss_id: 0,
        },
      },
      {
        id: "",
        is_required: true,
        criterion: "dateRange",
        value: {
          start_date: "",
          end_date: "",
          relative_date: this.relativeDate,
        },
      },
      {
        id: "",
        is_required: true,
        criterion: "groupUser",
        value: {
          role: this.role_id,
          ug_id: 999,
          userid: 999,
        },
      },
    ]

    if (this.campaignData && this.campaignData != "") {
      criteriaArray.push({
        id: "",
        value: { c_id: this.campaignData },
        criterion: "campaign",
        is_required: true,
      })
    }

    this.chartDataLoaded = false
    this.reInit()
    this.userReportService.getContactsByUser(criteriaArray).subscribe(
      (res: any) => {
        if (res.success === "true") {
          const forAggregation = res.data.slice(0)
          const afterAggregation: any = []
          // Aggregate the data so we don't have duplicate bars for each category.
          forAggregation.forEach((record, index, theArray) => {
            if (undefined !== afterAggregation && afterAggregation.length > 0) {
              const idx = afterAggregation.findIndex(
                item =>
                  item.category === record.category && record.category !== ""
              )
              if (idx !== -1) {
                afterAggregation[idx].number_of_occurrences =
                  parseInt(afterAggregation[idx].number_of_occurrences, 10) +
                  parseInt(record.number_of_occurrences, 10)
              } else {
                afterAggregation.push(record)
              }
            } else {
              afterAggregation.push(record)
            }
          })
          Logger().info(
            "getContactsByUser",
            "afterAggregation",
            afterAggregation
          )

          // Sort by total visits
          const byTotal = afterAggregation.slice(0)
          byTotal.sort(function (a, b) {
            return b.number_of_occurrences - a.number_of_occurrences
          })
          Logger().info("getContactsByUser", "byTotal", byTotal)

          byTotal.forEach(record => {
            if (record.category) {
              this.stackedBarChartData[0].data.push([
                record.category,
                parseInt(record.number_of_occurrences, 10),
              ])
            }
          })

          this.chartDataLoaded = true
          Logger().info(
            "getContactsByUser",
            "this.stackedBarChartData",
            this.stackedBarChartData
          )
        }
      },
      err => {
        this.toasterLibrary.error(null, err.error.message)
      }
    )
  }

  exportChartData() {
    const orgId = localStorage.getItem("account_id") ?? "0";

    this.chartDataLoaded = false
    this.helperService.chartCSVDownload(this.stackedBarChartData[0], `[${orgId}] ${this.widgetTitle}`)

    setTimeout(() => {
      this.chartDataLoaded = true
    }, 500);
  }

  private reInit() {
    this.stackedBarChartData = [
      {
        data: [],
        label: this.widgetTitle,
        color: "#e88b1b",
      },
    ]
  }
}
