<ng-template [ngxPermissionsOnly]="['users:can_get_users_reports_sales']">
  <div class="box-row panel panel-default">
    <div class="panel-heading">
      <div class="d-flex align-items-center justify-content-space-between">
        <h4>{{widgetTitle}}</h4>
        <div *ngIf="chartDataLoaded" class="refresh-listing-icon" title="Export" (click)="exportChartData()">
          <i class="fa fa-cloud-download fa-lg" aria-hidden="true"></i>
        </div>
      </div>
    </div>
    <div class="panel-body text-center">
      <div *ngIf="!chartDataLoaded" class="whirl standard spinnermargin"></div>
      <ng-template [ngIf]="chartDataLoaded">
        <stacked-bar-chart [stackedBarData]="stackedBarChartData" [stackedBarHeight]="250"></stacked-bar-chart>
      </ng-template>
    </div>
  </div>
</ng-template>