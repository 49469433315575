import { Component, Input, OnChanges, OnInit, SimpleChange } from '@angular/core';
import { LocalFilterStorageService, StatusReportService, ToasterLibraryService } from '@service/index';
import { NgxPermissionsService } from 'ngx-permissions';
import { ActivatedRoute } from '@angular/router';
// Add the observable and combineLatest
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/combineLatest';

@Component({
  selector: 'app-active-leads-widget',
  templateUrl: './active-leads-widget.component.html',
  styleUrls: ['./active-leads-widget.component.scss']
})

// @Privilege({
//   privilege: 'contacts:can_get_contacts_reports_count'
// })
export class ActiveLeadsWidgetComponent implements OnInit, OnChanges {
  @Input() criteriaArray: any;
  @Input() relativeDate: any;
  @Input() label = 'Open Leads';
  @Input() campaignData: any;
  public isCountLoaded = false;
  public routeCriteriaArray;
  public openLeadsCount = 0;
  constructor(
    private statusReportService: StatusReportService,
    private toasterLibrary: ToasterLibraryService,
    private permissionService: NgxPermissionsService,
    private localStorageLibrary: LocalFilterStorageService,
    private _route: ActivatedRoute
  ) {
    const urlParams = Observable.combineLatest(
      this._route.queryParams,
      (queryParams) => ({ ...queryParams })
    );

    urlParams.subscribe(routeParams => {
      this.criteriaArray = [
        {
          id: '',
          is_required: true,
          criterion: 'dateRange',
          value: {
            start_date: '',
            end_date: '',
            relative_date: this.relativeDate
          }
        }
      ];
      if (this.campaignData && this.campaignData != '') {
        this.criteriaArray.push({
          'id': '',
          'value': { c_id: this.campaignData },
          'criterion': 'campaign',
          'is_required': true
        });
      }

      // this.getOpenLeadsCount();
    });
  }

  ngOnInit() {
    /**
     * we want to check if @input was passed, if it was, use it
     * if it wasn't passed, then use local storage
     */
    if (this.permissionService.hasPermission('contacts:can_get_contacts_reports_count')) {
      if (this.criteriaArray === undefined && this.relativeDate !== undefined) {
        this.criteriaArray = [
          {
            id: '',
            is_required: true,
            criterion: 'dateRange',
            value: {
              relative_date: this.relativeDate
            }
          }
        ];

        if (this.campaignData && this.campaignData != '') {
          this.criteriaArray.push({
            'id': '',
            'value': { c_id: this.campaignData },
            'criterion': 'campaign',
            'is_required': true
          });
        }
      } else {
        // this.criteriaArray = JSON.parse(localStorage.getItem('leadsCriteria'));
        let filter_data = JSON.parse(this.localStorageLibrary.getItem());
        this.criteriaArray = filter_data?.leadsCriteria;
      }
      this.getOpenLeadsCount();
    }
  }

  /**
   *
   * @TODO Fix these widgets on update
   */

  ngOnChanges(changes: { [key: string]: SimpleChange }) {

    if (this.permissionService.hasPermission('contacts:can_get_contacts_reports_count')) {
      if (
        (changes['criteriaArray'] && !changes['criteriaArray'].isFirstChange())
      ) {
        this.getOpenLeadsCount();
      }
      if ((changes['relativeDate'] && !changes['relativeDate'].isFirstChange())) {
        this.criteriaArray = [
          {
            id: '',
            is_required: true,
            criterion: 'dateRange',
            value: {
              start_date: '',
              end_date: '',
              relative_date: this.relativeDate
            }
          }
        ];
        if (this.campaignData && this.campaignData != '') {
          this.criteriaArray.push({
            'id': '',
            'value': { c_id: this.campaignData },
            'criterion': 'campaign',
            'is_required': true
          });
        }

        this.getOpenLeadsCount();
      }
    }
  }
  setup() {

  }

  getOpenLeadsCount() {
    this.isCountLoaded = false;
    this.statusReportService.getOpenLeadsCount(this.criteriaArray).subscribe((res: any) => {
      this.openLeadsCount = res.data.count;
      this.isCountLoaded = true;
    }, (error: any) => {
      console.log(error);
    });
  }
}
