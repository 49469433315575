import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import 'rxjs/add/operator/map';
import { globalValues } from '@app/globals';
import { CustomHttp } from '@service/customhttp.service';
import { RequestLibraryService } from '@service/core/request-library.service';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class PhoneNumberService {
    private customHttp: CustomHttp;
    constructor(
        private http: HttpClient,
        private router: Router,
        private requestLibrary: RequestLibraryService
    ) {
        this.customHttp = new CustomHttp(http, router);
    }

    getPaginatedTrackingNumbers(body) {
        return this.requestLibrary.GetAll('/phonenumbers', {
            'options': body
        });
    }

    getExportTrackingNumbers(body) {
        return this.requestLibrary.GetCSV('/phonenumbers', {
            'options': body
        });
    }

    getAllTrackingNumbers() {
        return this.requestLibrary.GetAll('/phonenumbers', {
            'options': { page_size: 'all' }
        });
    }
    addNewTrackingNumber(model) {
        return this.requestLibrary.Post('/phonenumbers', model);
    }

    getTrackingNumberDetail(id) {
        return this.requestLibrary.Get('/phonenumbers/' + id);
    }

    getGlobalSettings() {
        return this.requestLibrary.Get('/phonenumbers/globalsettings/my');
    }

    updateGlobalSettings(model) {
        return this.requestLibrary.Post('/phonenumbers/globalsettings', model);
    }

    deactivateTrackingNumber(model) {
        return this.requestLibrary.Post('/phonenumbers/deactivate', model);
    }

    reactivateTrackingNumber(model) {
        return this.requestLibrary.Post('/phonenumbers/reactivate', model);
    }

    releaseTrackingNumber(model) {
        return this.requestLibrary.Post('/phonenumbers/release', model);
    }

    deleteTrackingNumber(id) {
        return this.requestLibrary.Delete('/phonenumbers/' + id);
    }

    getCompactTrackingNumbers() {
        return this.requestLibrary.GetAll('/phonenumbers', {
            'options': { page_size: 'all', is_compact: 'true' }
        });
    }
}
