<ng-template [ngxPermissionsOnly]="['campaigns:can_get_campaigns_reports_contact_per_campaign']">
  <div class="box-row panel panel-default">
    <div class="panel-heading">
      <div class="d-flex align-items-center justify-content-space-between">
        <h4>{{widgetTitle}}</h4>
        <div *ngIf="leadsByCampaignLoaded" class="refresh-listing-icon" title="Export" (click)="exportChartData()">
          <i class="fa fa-cloud-download fa-lg" aria-hidden="true"></i>
        </div>
      </div>
    </div>
    <div class="panel-body text-center">
      <div *ngIf="!leadsByCampaignLoaded" class="whirl standard spinnermargin"></div>
      <ng-template [ngIf]="leadsByCampaignLoaded">
        <pie-chart *ngIf="chartType=='pieChart'" [pieData]=leadsByCampaignData [showLegends]=true></pie-chart>
        <bar-chart *ngIf="chartType=='barChart'" [chartFor]="'largeSize'" [chartType]="'horizontalBar'"
          [barData]="leadsByCampaignData" [totalCount]="'7'"></bar-chart>
      </ng-template>
    </div>
  </div>
</ng-template>