<toaster-container [toasterconfig]="toasterconfig"></toaster-container>

<div class="container">
  <!-- Loading State -->
  <div *ngIf="isLoading" class="text-center my-4">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>

  <!-- Error State -->
  <div *ngIf="loadingError" class="alert alert-danger mt-3" role="alert">
    {{ loadingError }}
  </div>

  <!-- Main Content -->
  <div *ngIf="!loggedIn && !isLoading">
    <div class="page-header">
      <h1>Welcome <small>Company Information</small></h1>
    </div>

    <div class="row" *ngIf="loaded">
      <div class="col-md-12">
        <form
          name="signupForm"
          class="form-validate"
          #signupForm="ngForm"
          (ngSubmit)="onSubmit()"
          novalidate
        >
          <div class="panel panel-default">
            <div
              [ngClass]="{
                'panel-body': true,
                whirl: isSaving,
                shadow: isSaving,
                oval: isSaving,
                right: isSaving
              }"
            >
              <div class="panel-heading">
                <!-- Panel heading if needed -->
              </div>

              <div class="panel-body">
                <!-- Email Field - Always Read Only -->
                <div class="form-group">
                  <label class="control-label">Email</label>
                  <div>
                    <p class="form-control-static">
                      {{
                        auth0IncomingData?.mo_req_payload?.email || model.email
                      }}
                    </p>
                  </div>
                </div>

                <!-- Username Field - Conditional Display -->
                <div
                  class="form-group"
                  *ngIf="auth0IncomingData?.mo_req_payload?.username"
                >
                  <label class="control-label">User Name</label>
                  <div>
                    <p class="form-control-static">
                      {{ auth0IncomingData.mo_req_payload.username }}
                    </p>
                  </div>
                </div>

                <!-- First Name Field -->
                <ng-container
                  *ngIf="
                    auth0IncomingData?.mo_req_payload?.firstname;
                    else firstNameInput
                  "
                >
                  <div class="form-group">
                    <label class="control-label">First Name</label>
                    <div>
                      <p class="form-control-static">
                        {{ auth0IncomingData.mo_req_payload.firstname }}
                      </p>
                    </div>
                  </div>
                </ng-container>
                <ng-template #firstNameInput>
                  <div
                    class="form-group"
                    [ngClass]="{ 'has-error': formErrors.first_name }"
                  >
                    <label for="first_name" class="control-label"
                      >First Name *</label
                    >
                    <input
                      class="form-control"
                      type="text"
                      id="first_name"
                      name="first_name"
                      [(ngModel)]="model.first_name"
                      placeholder="First Name"
                      required
                      [ngClass]="{
                        'is-invalid': wrongSubmission && formErrors.first_name
                      }"
                    />
                    <span
                      class="text-danger"
                      *ngIf="wrongSubmission && formErrors.first_name"
                    >
                      First name is required
                    </span>
                  </div>
                </ng-template>

                <!-- Last Name Field -->
                <ng-container
                  *ngIf="
                    auth0IncomingData?.mo_req_payload?.lastname;
                    else lastNameInput
                  "
                >
                  <div class="form-group">
                    <label class="control-label">Last Name</label>
                    <div>
                      <p class="form-control-static">
                        {{ auth0IncomingData.mo_req_payload.lastname }}
                      </p>
                    </div>
                  </div>
                </ng-container>
                <ng-template #lastNameInput>
                  <div
                    class="form-group"
                    [ngClass]="{ 'has-error': formErrors.last_name }"
                  >
                    <label for="last_name" class="control-label"
                      >Last Name *</label
                    >
                    <input
                      class="form-control"
                      type="text"
                      id="last_name"
                      name="last_name"
                      [(ngModel)]="model.last_name"
                      placeholder="Last Name"
                      required
                      [ngClass]="{
                        'is-invalid': wrongSubmission && formErrors.last_name
                      }"
                    />
                    <span
                      class="text-danger"
                      *ngIf="wrongSubmission && formErrors.last_name"
                    >
                      Last name is required
                    </span>
                  </div>
                </ng-template>

                <!-- Company Name Field -->
                <div
                  class="form-group"
                  [ngClass]="{ 'has-error': formErrors.name }"
                >
                  <label for="name" class="control-label">Company Name *</label>
                  <input
                    class="form-control"
                    type="text"
                    id="name"
                    name="name"
                    [(ngModel)]="model.name"
                    placeholder="Company Name"
                    required
                    [ngClass]="{
                      'is-invalid': wrongSubmission && formErrors.name
                    }"
                  />
                  <span
                    class="text-danger"
                    *ngIf="wrongSubmission && formErrors.name"
                  >
                    Company name is required
                  </span>
                </div>

                <!-- Company Suite Selection -->
                <div class="form-group">
                  <label for="company_suite" class="control-label"
                    >Company Suite *</label
                  >
                  <select
                    class="form-control"
                    id="company_suite"
                    name="company_suite"
                    [(ngModel)]="selectedAppVersion"
                    (ngModelChange)="setAppVersion($event)"
                    required
                  >
                    <option value="" disabled selected>
                      Select a version suite
                    </option>
                    <option value="automation">
                      Marketing Automation Suite
                    </option>
                    <option value="aggregation">Lead Aggregation Suite</option>
                    <option value="addiction">Addiction Treatment Suite</option>
                  </select>
                  <span
                    class="text-danger"
                    *ngIf="wrongSubmission && !selectedAppVersion"
                  >
                    Please select a company suite
                  </span>
                </div>
              </div>

              <!-- Form Footer -->
              <div class="panel-footer">
                <div class="d-flex justify-content-between align-items-center">
                  <button
                    class="btn btn-primary btn-lg"
                    type="submit"
                    [disabled]="
                      isSaving || !signupForm.form.valid || !selectedAppVersion
                    "
                  >
                    <span *ngIf="!isSaving">Submit</span>
                    <span *ngIf="isSaving">
                      <i class="fas fa-spinner fa-spin mr-2"></i>
                      Submitting...
                    </span>
                  </button>
                  <small class="text-muted">* Required fields</small>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
