import { Component, Input, OnChanges, OnInit, SimpleChange } from '@angular/core';
import { Privilege } from '@decorator/index';
import { ContactReportService, HelpersService, ToasterLibraryService } from '@service/index';
import * as _ from 'lodash';
import { NgxPermissionsService } from 'ngx-permissions';
@Component({
  selector: 'app-new-customer-map-widget',
  templateUrl: './new-customer-map-widget.component.html',
  styleUrls: ['./new-customer-map-widget.component.scss'],
})

// @Privilege({
//   privilege: 'contacts:can_get_contacts_reports_count_per_city'
// })
export class NewCustomerMapWidgetComponent implements OnInit, OnChanges {
  @Input() relativeDate: any;
  @Input() campaignData: any;
  public widgetTitle = "New Customer Map"
  public seriesData = {};
  public markersData: Array<any> = [];
  public patientsMapLoaded: boolean = false;
  constructor(
    private contactReportService: ContactReportService,
    private toasterLibrary: ToasterLibraryService,
    private permissionService: NgxPermissionsService,
    private helperService: HelpersService
  ) { }

  ngOnInit() {
    if (this.permissionService.hasPermission('contacts:can_get_contacts_reports_count_per_city')) {
      this.getNewPatientsMap();
    }
  }
  ngOnChanges(changes: { [key: string]: SimpleChange }) {
    if (this.permissionService.hasPermission('contacts:can_get_contacts_reports_count_per_city')) {
      if (
        (changes['relativeDate'] && !changes['relativeDate'].isFirstChange()) ||
        (changes['campaignData'] && !changes['campaignData'].isFirstChange()) ||
        (changes['criteriaArray'] && !changes['criteriaArray'].isFirstChange())
      ) {
        this.getNewPatientsMap();
      }
    }
  }
  getNewPatientsMap() {


    const criteriaArray: any = [
      {
        id: '',
        is_required: true,
        criterion: 'dateRange',
        value: {
          start_date: '',
          end_date: '',
          relative_date: this.relativeDate
        }
      }
    ];

    if (this.campaignData && this.campaignData != "") {
      criteriaArray.push({
        'id': '',
        'value': { c_id: this.campaignData },
        'criterion': 'campaign',
        'is_required': true
      });
    }

    this.patientsMapLoaded = false;
    let countriesData = [];
    this.contactReportService
      .getPatientsMap(criteriaArray)
      .subscribe((res: any) => {
        if (res.success == 'true') {
          res.data.forEach((patient, index) => {
            const objIndex = _.findIndex(countriesData, ['country', patient.country]);
            if (objIndex != -1) {
              countriesData[objIndex].patients = parseInt(countriesData[objIndex].patients, 10) + parseInt(patient.total_sales, 10);
            } else {
              countriesData.push({
                'country': patient.country,
                'patients': patient.total_sales
              });
            }
            this.markersData.push({ latLng: [patient.latitude, patient.longitude], name: patient.city });
          });
        }
        //Make Series data to get patients in one country//
        countriesData.forEach(country => {
          let key = country.country;
          this.seriesData[key] = country.patients;
        });
        this.patientsMapLoaded = true;
      }, err => {
        this.toasterLibrary.error(null, err.error.message);
      });
  }

  exportChartData() {
    const orgId = localStorage.getItem("account_id") ?? "0";

    this.patientsMapLoaded = false
    this.helperService.chartCSVDownload(this.markersData, `[${orgId}] ${this.widgetTitle}`)

    setTimeout(() => {
      this.patientsMapLoaded = true
    }, 500);
  }
}
