import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChange,
} from "@angular/core"
import { Privilege } from "@decorator/index"
import {
  ContactReportService,
  HelpersService,
  ToasterLibraryService,
} from "@service/index"
import { NgxPermissionsService } from "ngx-permissions"
import { Logger } from "@service/global.funcs"

@Component({
  selector: "app-contact-captured-line-chart-widget",
  templateUrl: "./contact-captured-line-chart-widget.component.html",
  styleUrls: ["./contact-captured-line-chart-widget.component.scss"],
})

// @Privilege({
//   privilege: 'contacts:can_get_contacts_reports_captured'
// })
export class ContactCapturedLineChartWidgetComponent
  implements OnInit, OnChanges
{
  @Input() relativeDate: any
  @Input() campaignData: any
  @Input("chartLabel") chartLabel = "Leads Captured"

  public isContactCapturedLoaded = false
  public capturedCount = 0
  public leadsChartData = {
    labels: [],
    datasets: [],
    legend: false,
  }
  testData = {
    labels: [
      "2021-01-01",
      "2021-01-10",
      "2021-01-22",
      "2021-02-08",
      "2021-02-22",
      "2021-02-27",
    ],
    data: ["2", "3", "0", "1", 2, 0],
  }
  constructor(
    private contactReportService: ContactReportService,
    private toasterLibrary: ToasterLibraryService,
    private permissionService: NgxPermissionsService,
    private helperService: HelpersService
  ) {}

  ngOnInit() {
    if (
      this.permissionService.hasPermission(
        "contacts:can_get_contacts_reports_captured"
      )
    ) {
      this.requestContactCaptured()
    }
  }

  ngOnChanges(changes: { [key: string]: SimpleChange }) {
    if (
      this.permissionService.hasPermission(
        "contacts:can_get_contacts_reports_captured"
      )
    ) {
      if (
        (changes["relativeDate"] && !changes["relativeDate"].isFirstChange()) ||
        (changes["campaignData"] && !changes["campaignData"].isFirstChange()) ||
        (changes["criteriaArray"] && !changes["criteriaArray"].isFirstChange())
      ) {
        this.requestContactCaptured()
      }
    }
  }

  requestContactCaptured() {
    console.log("In leads captured wegit===============")

    const criteria = [
      {
        is_required: true,
        criterion: "dateRange",
        value: {
          relative_date: this.relativeDate,
        },
      },
      {
        value: { c_id: this.campaignData },
        criterion: "campaign",
        is_required: true,
      },
    ]

    this.isContactCapturedLoaded = false
    this.reInit()
    this.contactReportService.getCapturedContacts(criteria).subscribe(
      (res: any) => {
        if (res.success === "true") {
          Logger().info("requestContactCaptured", "res", res)
          this.leadsChartData.datasets.push({
            label: this.chartLabel,
            backgroundColor: "rgba(192,135,66,0.2)",
            borderColor: "#e88b1b",
            pointBorderColor: "#fff",
            data: [],
          })

          res.data.forEach(contact => {
            if (!this.leadsChartData.labels.includes(contact.date_month)) {
              this.leadsChartData.labels.push(contact.date_month)
            }
            this.leadsChartData.datasets[0].data.push(
              contact.number_of_occurrences
            )
            this.capturedCount =
              this.capturedCount + parseInt(contact.number_of_occurrences, 10)
          })
          // console.log('leadsChartData:',this.leadsChartData);
          // console.log('capturedCount:',this.capturedCount);
          // this.leadsChartData.labels = this.testData.labels;
          //  this.leadsChartData.datasets[0].data = this.testData.data;
          //  this.capturedCount = 6;
        }

        this.isContactCapturedLoaded = true
      },
      err => {
        this.toasterLibrary.error(null, err.error.message)
      }
    )
  }

  exportChartData() {
    const orgId = localStorage.getItem("account_id") ?? "0";
    this.isContactCapturedLoaded = false

    this.helperService.chartCSVDownload(this.leadsChartData, `[${orgId}] Leads Captured`)

    setTimeout(() => {
      this.isContactCapturedLoaded = true
    }, 500);
  }

  private reInit() {
    this.leadsChartData = {
      labels: [],
      datasets: [],
      legend: false,
    }
  }
}
