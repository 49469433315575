import { Component, Input, OnChanges, OnInit, SimpleChange } from '@angular/core';
// import { Privilege } from '@decorator/index';
import { ContactReportService, HelpersService, ToasterLibraryService } from '@service/index';
import { NgxPermissionsService } from 'ngx-permissions';
import { Logger } from '@service/global.funcs';
import * as _ from 'lodash';


@Component({
  selector: 'app-addiction-top-admissions-by-payors-chart-widget',
  templateUrl: './addiction-top-admissions-by-payors-chart-widget.component.html',
  styleUrls: ['./addiction-top-admissions-by-payors-chart-widget.component.scss']
})

export class AddictionTopAdmissionsByPayorsChartWidgetComponent implements OnInit, OnChanges {
  @Input() relativeDate: any;
  @Input() widgetTitle = 'Admissions Payor Mix';
  @Input() field = '3001';
  @Input() chartAspectRatio: any;
  @Input() campaignData: any;

  public stackedBarChartData = [{
    data: [],
    label: this.widgetTitle,
    color: '#1fafff',
  }];
  public total_sales;
  public chartDataLoaded = false;
  constructor(
    private contactReportService: ContactReportService,
    private toasterLibrary: ToasterLibraryService,
    private permissionService: NgxPermissionsService,
    private helperService: HelpersService
  ) { }

  ngOnInit() {
    if (this.permissionService.hasPermission('contacts:can_get_contacts_reports_count_per_field_type')) {
      this.getContactsPerField();
    }
  }

  ngOnChanges(changes: { [key: string]: SimpleChange }) {

    if (this.permissionService.hasPermission('contacts:can_get_contacts_reports_count_per_field_type')) {
      if (
        (changes['relativeDate'] && !changes['relativeDate'].isFirstChange()) ||
        (changes['campaignData'] && !changes['campaignData'].isFirstChange()) ||
        (changes['criteriaArray'] && !changes['criteriaArray'].isFirstChange())
      ) {
        this.getContactsPerField();
      }
    }

  }
  getContactsPerField() {
    const criteriaArray: any = [
      {
        id: '',
        is_required: true,
        criterion: 'dateRange',
        value: {
          start_date: '',
          end_date: '',
          relative_date: this.relativeDate
        }
      },
      {
        id: '',
        is_required: true,
        criterion: 'statusSubstatus',
        value: {
          ostat_id: 2,
          ss_id: 0
        }
      }
    ];

    if (this.campaignData && this.campaignData != "") {
      criteriaArray.push({
        'id': '',
        'value': { c_id: this.campaignData },
        'criterion': 'campaign',
        'is_required': true
      });
    }

    this.chartDataLoaded = false;
    this.reInit();
    this.contactReportService.getContactsByFieldValue(criteriaArray, this.field).subscribe(
      (res: any) => {
        if (res.success == 'true') {
          const forAggregation = res.data.slice(0);
          const afterAggregation: any = [];
          // Aggregate the data so we don't have duplicate bars for each category.
          forAggregation.forEach((record, index, theArray) => {
            if (undefined !== afterAggregation && afterAggregation.length > 0) {
              const idx = afterAggregation.findIndex(item => item.category === record.category);
              if (idx !== -1) {
                afterAggregation[idx].number_of_occurrences =
                  parseInt(afterAggregation[idx].number_of_occurrences, 10) + parseInt(record.number_of_occurrences, 10);
              } else {
                afterAggregation.push(record);
              }
            } else {
              afterAggregation.push(record);
            }
          });
          // Sort by total visits
          const byTotalVisits = afterAggregation.slice(0);
          byTotalVisits.sort(function (a, b) {
            return b.number_of_occurrences - a.number_of_occurrences;
          });

          byTotalVisits.forEach(record => {
            if (record.category) {
              this.stackedBarChartData[0].data.push([record.category, parseInt(record.number_of_occurrences, 10)]);
            }
          });
          Logger().info('getByChannel', 'this.stackedBarChartData', this.stackedBarChartData);
        }
        this.chartDataLoaded = true;
      },
      err => {
        this.toasterLibrary.error(null, "Data is not available.");
      }
    );
  }

  exportChartData() {
    const orgId = localStorage.getItem("account_id") ?? "0";

    this.chartDataLoaded = false
    this.helperService.chartCSVDownload(this.stackedBarChartData[0], `[${orgId}] ${this.widgetTitle}`)

    setTimeout(() => {
      this.chartDataLoaded = true
    }, 500);
  }
  
  private reInit() {
    this.stackedBarChartData = [{
      data: [],
      label: this.widgetTitle,
      color: '#1fafff',
    }];
  }
}
