<ng-template [ngxPermissionsOnly]="['contacts:can_get_contacts_reports_count_per_field_type']">
  <div class="box-row panel panel-default">
    <div class="panel-heading">
      <div class="d-flex align-items-center justify-content-space-between">
        <h4>{{widgetTitle}}</h4>
        <div *ngIf="chartDataLoaded" class="refresh-listing-icon" title="Export" (click)="exportChartData()">
          <i class="fa fa-cloud-download fa-lg" aria-hidden="true"></i>
        </div>
      </div>
    </div>
    <div class="panel-body text-center">
      <div *ngIf="!chartDataLoaded" class="whirl standard spinnermargin"></div>
      <ng-template [ngIf]="chartDataLoaded">
        <!-- <line-chart [chartData]="leadsChartData" [totalCount]="capturedCount"></line-chart> -->
        <!-- <bar-chart [barData]="barChartData" [totalCount]="barChartData.totalVisits" chartFor="normalSize" [chartDisableAspectRatio]="chartAspectRatio"></bar-chart> -->
        <stacked-bar-chart [stackedBarData]="stackedBarChartData"></stacked-bar-chart>
      </ng-template>
    </div>
  </div>
</ng-template>