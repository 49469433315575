import { Injectable } from "@angular/core"
import { AppConfig } from "@service/app.config"
import { ToasterConfig, ToasterService } from "angular2-toaster"

@Injectable()
export class ToasterLibraryService {
  // TOASTER
  toasterConfig: any
  toasterconfig: ToasterConfig = new ToasterConfig({
    positionClass: AppConfig.toasterPositionClass,
    showCloseButton: AppConfig.toasterShowCloseButton,
  })

  private default: any = {
    error: {
      title: "Whoops. That didn't work.",
      description: "Please try again later.",
    },
    success: {
      title: "Success!",
      description: "",
    },
    warning: {
      title: "Warning!",
      description: "",
    },
    info: {
      title: "Information!",
      description: "",
    },
  }

  constructor(private toasterService: ToasterService) {}

  error(title = null, description = null) {
    console.error(
      "toaster-library error",
      "title",
      title,
      "description",
      description
    )
    title = title || this.default.error.title
    description = description || this.default.error.description
    this._pop("error", title, description)
  }

  success(title = null, description = null) {
    title = title || this.default.success.title
    description = description || this.default.success.description

    this._pop("success", title, description)
  }
  warning(title = null, description = null) {
    title = title || this.default.warning.title
    description = description || this.default.warning.description

    this._pop("warning", title, description)
  }

  info(title = null, description = null) {
    title = title || this.default.info.title
    description = description || this.default.info.description

    this._pop("info", title, description)
  }

  private _pop(type, title, text) {
    this.toasterService.pop(type, title, text)
  }
  public getToasterConfig() {
    return this.toasterconfig
  }
}
